





































































import {storage, db, sts, auth} from "../db.js"

import XLSX from "xlsx"
import Spreadsheet from "x-data-spreadsheet"

import {generate_iIDs} from "../xlsm"
import {create_plan} from "../ds"
import {check_plan_filename} from "../utils"
import plan from "../store/modules/plan"

plans_ref = db.collection 'plans'

import {mapActions, mapGetters} from "vuex"

export default

  name: "PlanUpload"

  data: ->
    dialog: false
    alert: false
    file: null
    loading: false
    uploadPercent: 0
    mp_data: []
    tmp_data: []
    mp_headers: []
    tmp_headers: []
    xlsm: null
    spreadsheet: undefined
    aiplan_id: null

  computed: {
    ...mapGetters(["new_plan"])
    api_server: -> plan.api_server
    planCols: -> (rp for rp in @tool_plan when rp[1]?)
  }
  
  mounted: ->
    console.log plan, plan.api_server

  watch:
    new_plan: (n, o)->
      if n?.modified_file?
        req = new XMLHttpRequest
        url = "#{@api_server}/#{n.modified_file}"
        console.log @api_server, n.modified_file
        req.open "GET", url
        req.responseType = "arraybuffer";
        req.onload = (e)=>
          data = req.response
          if data? 
            xlsm = new Uint8Array data
            wb = XLSX.read xlsm, {type:'array'}
            se = @$refs.spreadsheet
            ss = new Spreadsheet(se, {
              view:
                width: =>se.clientWidth
                height: =>window.innerHeight - 200
              col: len: 128
            })
            ss.loadData(@stox wb)
        req.send null

  methods: {

    # vuex
    ...mapActions ['uploadXlsm']

    # конвертиурет SheetJs workbook в x-spreadsheet формат
    stox: (wb) ->
      out = []
      wb.SheetNames.forEach (name) ->
        o = 
          name: name
          rows: {}
        ws = wb.Sheets[name]
        aoa = XLSX.utils.sheet_to_json(ws,
          raw: false
          header: 1)
        aoa.forEach (r, i) ->
          cells = {}
          r.forEach (c, j) ->
            cells[j] = text: c
            return
          o.rows[i] = cells: cells
          return
        out.push o
        return
      out

    
    uploadFile: ->      
      try 
        create_plan { 
          name: @file.name
          aiplan_id: @aiplan_id
          user: auth?.currentUser?.email
          data: @data }
      catch err
        @alert = "План загрузить не удалость!\n#{err}"

      # reset all
      @file = null
      @data = []
      @headers = []
      @dialog = false

    uploadSpreadsheet: (file)->
      return unless file?

      unless check_plan_filename(file.name)
        @alert = "<strong>Некорректное имя файла плана!</strong></br>Имя файла должно в начале содержать <b>aiplan id</b>, отделенный пробелом.</br>Пример: <pre>\"IMG-44-GKX-2418 Хорошевский_ТехМП_Сентябрь.xlsx\"</pre>"
      else 
        @alert = null
      
      @aiplan_id = file.name[0...file.name.indexOf(" ")] 
      upload_xlsx = (e)=>
      
        @uploadXlsm {xlsm: e.target.result, name: file.name}

        ### 
          # Модифицируем xlsm файл, генерируем iIDs
          xlsm = new Uint8Array e.target.result
          xlsm = await generate_iIDs xlsm      
          res = @uploadXlsm {xlsm, name: file.name}
          res = @uploadXlsm {xlsm: e.target.result, name: file.name}
          @wb = XLSX.read xlsm, {type:'array'}

          # Технический медиа план
          sheet = wb.Sheets[wb.SheetNames[0]]
          @tmp_data = XLSX.utils.sheet_to_row_object_array(sheet, {}).map (row, id)=>{id, ...row}
          @tmp_headers = Object.keys(@tmp_data[0]).map (k)-> {text:k, value: k}

          # Медиаплан
          sheet = wb.Sheets[wb.SheetNames[1]]
          @mp_data = XLSX.utils.sheet_to_json(sheet, {range: 9, header: "A"}).map (row, id)=>{id, ...row}
          @mp_headers = Object.keys(@mp_data[0]).map (k)-> {text:k, value: k}

          se = @$refs.spreadsheet
          ss = new Spreadsheet(se, {
            view:
              width: =>se.clientWidth
              height: =>window.innerHeight - 200
            col: len: 128
          })
          ss.loadData(@stox wb)
        ###
        
      reader = new FileReader()
      reader.addEventListener('load', upload_xlsx)
      reader.readAsArrayBuffer(file)
  } # methods

