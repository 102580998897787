

































import { plan_tool_cols, tool_cols, col_id, pg_tool_cols} from '../config'
import { mapGetters, mapActions } from "vuex"
import { HotTable, HotColumn } from '@handsontable/vue';
import DatePicker from "./DatePicker.vue"


# Утилиты для настройки таблицы

# преобразует дату в сокращенный варинат
date_rnd = (instance, td, row, col, prop, value, cellProperties)=>
  value = new Date value
  value = "#{value.getFullYear()}-#{('0'+(value.getMonth()+1))[-2..]}-#{('0'+value.getDate())[-2..]}" if value.getFullYear?
  td.innerText = value
  td.style.textAlign = "center"
  td

# вывод json
object_rnd = (instance, td, row, col, prop, value, cellProperties)=>
  value ?= {}
  td.innerText = JSON.stringify(value)
  td

# Форматирование чисел
numeric_rnd = (instance, td, row, col, prop, value, cellProperties)=>
  v = parseFloat value
  return td.innerText='' if Number.isNaN v
  td.style.textAlign = 'right'
  td.innerText = if v >= 10
    Math.round(v).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  else
    v.toFixed(2)

# Настройка колонок

# columns = plan_tool_cols.map (c)->
#   data: c[1]
#   title: c[1]
#   editor: false
#   readOnly: true

# Мепим конфиг
columns = pg_tool_cols.map (c)->
  data: c[0]
  title: c[0]
  editor: false
  readOnly: true

# Рендер дата
columns
.filter (c)->c.title[-5..] is '_date'
.map (c)->
  c.renderer = date_rnd
  # c.type = "date"
  # c.dateFormat = "YYYY-MM-DD"
  c.alignment = "center"
  # c.correctFromat = true

# Рендер объектов
# columns[col_ids.fact_misc].renderer = object_rnd

# Рендер числовых значений
# [ "plan_views", "fact_views", "plan_clicks", "fact_clicks",
#   "plan_spent", "fact_spent", "plan_conv", "fact_conv",
#   "plan_cr", "fact_cr", "plan_vtr", "fact_vtr" ].map (n)->
#   c = columns[col_ids[n]]
#   c.renderer = numeric_rnd

# Редактор плановых значений
# [ "plan_views", "plan_clicks",
#   "plan_spent", "plan_conv",
#   "plan_cr"   , "plan_vtr" ].map (n)->
#   c = columns[col_ids[n]]
#   c.editor = "numeric"
#   c.readOnly = false
  
# dataSchema    = plan_tool_cols.map (c)-> [c[1]]: null
# colHeaders    = plan_tool_cols.map (c)-> c[1]

dataSchema    = pg_tool_cols.map (c)-> [c[0]]: null
colHeaders    = pg_tool_cols.map (c)-> c[0]

hiddenColumns =
  columns: []
  indicators: false

export default

  name: "ToolsListH"  
  components: {DatePicker, HotTable, HotColumn}
  props: ["filteredPlan"]
  data: -> 
    hotRef: null
    planFilter: null
    settings: {
      data: null
      dataSchema
      colHeaders
      columns
      hiddenColumns
      licenseKey: 'non-commercial-and-evaluation'
      rowHeaders: true 
      colHeaders: true
      filters: true
      dropdownMenu: true
      columnSorting: true
      afterChange: (changes, source)=>
        return unless changes?
        hot = @hotRef?.hotInstance
        # get unique changed row ids
        rows = [... new Set changes.map(([r])->r)]
        tools = rows.map (r)->hot.getSourceDataAtRow hot.toPhysicalRow r
        if hot?
          @updateTools tools
    } # settings
    
  watch:
    filteredPlan: (plan)->
      @planFilter = plan.aiplan_id
      @$nextTick =>@updatePlanFilter()

    allTools: (data)->
      @hotRef.hotInstance.loadData(data);

  mounted: ->
    @hotRef = @$refs.table
    @updatePlanFilter()
    # @$store.subscribe (mutation, state)-> @updateVuexPreview()
    # @$store.commit 'updateData', @hotRef.getSourceData()

  computed: {
    ...mapGetters ["allTools"]
  }
  
  methods: {
    updatePlanFilter: ->
      fp = @hotRef.hotInstance.getPlugin 'filters'
      fp?.clearConditions(0)
      fp?.addCondition(0, 'eq', [@planFilter]);
      fp?.filter()

    clearPlanFilter: ->
      fp = @hotRef.hotInstance.getPlugin 'filters'
      fp?.clearConditions(0)
      fp?.filter()
      # setTimeout (=>@updatePlanFilter()), 100

    clearDatesFilter: ->
      fp = @hotRef.hotInstance.getPlugin 'filters'
      fp?.clearConditions(2)
      fp?.clearConditions(3)
      fp?.filter()

    datesChanged: (dates)->
      console.log dates
      if @hotRef
        fp = @hotRef.hotInstance.getPlugin 'filters'
        fp?.clearConditions(2)
        fp?.clearConditions(3)
        fp?.addCondition(2, 'between', [dates.start_date, dates.end_date]);
        fp?.addCondition(3, 'between', [dates.start_date, dates.end_date ]);
        fp?.filter();
      
    ...mapActions ["updateTools"]
  }
   

