import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'

import Vue from 'vue'
import { firestorePlugin } from 'vuefire'
Vue.use(firestorePlugin)

const  firebaseConfig = {
    apiKey: "AIzaSyBz3qs8HGG2ashAPLM0ssbt_MnkW-EoHCI",
    authDomain: "i-com-agency.firebaseapp.com",
    databaseURL: "https://i-com-agency.firebaseio.com",
    projectId: "i-com-agency",
    storageBucket: "i-com-agency.appspot.com",
    messagingSenderId: "163899246108",
    appId: "1:163899246108:web:6e2a6b83e9bc3d89e841d9",
    measurementId: "G-W6JSNCL0QS"
};

const app = firebase.initializeApp(firebaseConfig)
export const db = app.firestore()
export const storage = app.storage()
export const auth = app.auth()
export const sts =  firebase.firestore.FieldValue.serverTimestamp
// export { Timestamp, GeoPoint }
