































export default
  name: "DatePicker"
  data: ->
    start_date: new Date().toISOString()[...10]
    end_date: new Date().toISOString()[...10] 
    menu1: false
    menu2: false

