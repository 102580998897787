import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login'
import PlanFact from '../components/PlanFact'
import Fact from '../components/Fact'
import FactDetails from '../components/FactDetails'

// import ClientDetails from '../components/ClientDetails'
// import Domains from '../components/Domains'
// import DomainDetails from '../components/DomainDetails'
// import URLDetails from '../components/URLDetails'

import {auth} from '../db'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'PlanFact',
    component: PlanFact,
    meta: {auth: true}
  },
  {
    path: '/fact',
    name: 'Fact',
    component: Fact,
    meta: {auth: true}
  },
  {
    path: '/fact/:platform/:id',
    name: 'FactDetails',
    component: FactDetails,
    meta: {auth: true}
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {noauth: true}
  },    
// {
//     path: '/client/:client_id',
//     name: 'client',
//     component: ClientDetails,
//     props: true,
//     meta: {auth: true}
// },
// {
//     path: '/domains',
//     name: 'domains',
//     component: Domains,
//     props: true,
//     meta: {auth: true}
// },
// {
//     path: '/domain/:domain_id',
//     name: 'domain',
//     component: DomainDetails,
//     props: true,
//     meta: {auth: true}
// },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next)=> {
  let user = auth.currentUser;
  console.log(user);
  // Если не требуется авторизация
  if(to.matched.some(record => record.meta.auth)) {
      // И пользователь не вошел, отправляем в логин
      if(!user) {
          return next({
              path: '/login',
              query: {
                  redirect: to.fullPath
              }
          })
      } 
  // Если раздел только для неавторизованных
  } else if (to.matched.some(record => record.meta.noauth)) {
      if(user) {
          return next({
              path: '/',
              query: {
                  redirect: to.fullPath
              }
          })
      }
  }
  next()
})

export default router
