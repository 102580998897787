




































import {mapGetters, mapActions} from "vuex"
import UPlot from "./UPlot"

export default

  name: "Improvado"
  components: {UPlot}

  data: ->
    nameFilter: null
    headers: [
      {text: "Название"  , value: "name"         }
      {text: "Начало"    , value: "start_date"   }
      {text: "Окончание" , value: "end_date"     }
      {text: "Потрачено" , value: "spent"        }
      {text: "Просмотров", value: "shows"        }
      {text: "Кликов"    , value: "clicks"       }
    ]
    
  methods: {
    updateNameFilter: ->
      @searchFacts name: @nameFilter

    clearNameFilter: ->

    onClick: (platform, item)->
      @$router.push "fact/#{platform}/#{item.name}"

    ...mapActions ["searchFacts"]
  }

  computed:
    mapGetters ["found_facts"]

