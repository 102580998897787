





import {mapGetters, mapActions} from "vuex"

export default
  name: "UPlot"
  props: ['item']
  data: ->{}
  watch:
    item: (new_v, old_v)->
       
      w = @$refs["plot"].clientWidth
      opts = {
        width: w
        height: 320
        scales: {
          x: auto: true
          y: auto: true
        }
        series: [
          {}
          {label: "clicks", stroke: "red"  }
          {label: "shows" , stroke: "blue" }
          {label: "spent" , stroke: "green"}
        ]
      }
      item = new_v
      if item?.date?
        dates = item.date.map((d)=>new Date(d).getTime()/1000)
        ids = dates.map((_, i)=>i).sort((a,b)=>dates[a]-dates[b])
        data = [
          ids.map (i)=>dates[i]
          ids.map (i)=>item.clicks[i]
          ids.map (i)=>item.shows[i]
          ids.map (i)=>item.spent[i]
        ]
      else data = []
      new uPlot opts, data, @$refs["plot"]


