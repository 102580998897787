{ tool_cols, plan_tool_cols, col_name_normalize } = require './config'

# Конвертиурет экселевскую дату
# https://github.com/SheetJS/sheetjs/blob/768e1bdcf8d1820bdb69b62252c4b53ab22b10f8/bits/10_ssf.js#L151

parse_date_code = (v, opts, b2) ->
  if v > 2958465 or v < 0
    return null
  date = v | 0
  time = Math.floor(86400 * (v - date))
  dow = 0
  dout = []
  out = D: date , T: time , u: 86400 * (v - date) - time , y: 0 , m: 0 , d: 0 , H: 0 , M: 0 , S: 0 , q: 0
  if Math.abs(out.u) < 1e-6
    out.u = 0
  if opts and opts.date1904
    date += 1462
  if out.u > 0.9999
    out.u = 0
    if ++time == 86400
      out.T = time = 0
      ++date
      ++out.D
  if date == 60
    dout = if b2 then [ 1317 , 10 , 29 ] else [ 1900 , 2 , 29 ]
    dow = 3
  else if date == 0
    dout = if b2 then [ 1317 , 8 , 29 ] else [ 1900 , 1 , 0 ]
    dow = 6
  else
    if date > 60
      --date

    ### 1 = Jan 1 1900 in Gregorian ###

    d = new Date(1900, 0, 1)
    d.setDate d.getDate() + date - 1
    dout = [
      d.getFullYear()
      d.getMonth() + 1
      d.getDate()
    ]
    dow = d.getDay()
    if date < 60
      dow = (dow + 6) % 7
    if b2
      dow = fix_hijri(d, dout)
  out.y = dout[0]
  out.m = dout[1]
  out.d = dout[2]
  out.S = time % 60
  time = Math.floor(time / 60)
  out.M = time % 60
  time = Math.floor(time / 60)
  out.H = time
  out.q = dow
  out

# конвертация в список инструментов
plan_to_tool = (plan)->
  # пробегаем по колонкам отчета
  tool = {}
  for c in plan_tool_cols
    l = c[0]
    n = c[1]
    t = c[3]
    # для каждой колонки имеющей тест ищем соответсвие в плане
    if t?
      for k, v of plan
        # нормализоуем имя и сверяем
        if t is col_name_normalize k
          tool[n] = v 
          break
    # выставляем дефолт для прочих случаев
    tool[n]?=""
    
    # если тип эксель-дата, приводим её к нормальной форме
    if l in ["start_date", "stop_date"] and tool[n] isnt ""
      d = parse_date_code tool[n]
      tool[n] = new Date(Date.UTC(d.y, d.m-1, d.d)) if d?
      # tool[n] = d

  tool

# Собирает уникальных клиентов, бренд, инструменты, кампании
tools_to_meta = (tools)->
  meta_cols =  ["client", "brand", "tool", "campaign"]
  meta = {}
  meta[n+"s"] = new Set for n in meta_cols
  for row in tools
    for n in meta_cols
      meta[n+"s"].add row[tool_cols[n]]
  meta[k] = [...meta[k]] for k, v of meta
  meta

# Имя файла плана должно выгляедть так:
# ABC123-123-123 Название плана.xlsx
# Т.е иметь разделитель " " и id разделенный подчеркиванием
check_plan_filename = (n)->
  # удаляем путь если есть
  n = n.replace /^.*[\\\/]/, ''
  # наличие пробела в имени
  s = n.indexOf ' '
  # наличие - в aiplan id
  u = n.indexOf '-'
  s >=0 and u>0 and u < s

module.exports = {
  plan_to_tool
  tools_to_meta
  check_plan_filename
}
