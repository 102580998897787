












import {mapGetters, mapActions} from "vuex"
import UPlot from "./UPlot"

export default

  name: "FactDetails"
  components: {UPlot}

  data: -> {
    headers: [
      {text: "Дата"      , value: "date"  }
      {text: "Расходы"   , value: "spent" }
      {text: "Просмотры" , value: "shows" }
      {text: "Клики"     , value: "clicks"}
    ]
    plot: undefined
  }

  methods: {
    plot_statistics: (stat)->
      w = @$refs["plot"].clientWidth
      opts = {
        width: w
        height: 320
        scales: {
          x: auto: true
          y: auto: true
        }
        series: [
          {}
          {label: "clicks", stroke: "red"  }
          {label: "shows" , stroke: "blue" }
          {label: "spent" , stroke: "green"}
        ]
      }
     
      data = []
      if stat?.date?
        dates = stat.date.map((d)=>new Date(d).getTime()/1000)
        ids = dates.map((_, i)=>i).sort((a,b)=>dates[a]-dates[b])
        data = [
          ids.map (i)=>dates[i]
          ids.map (i)=>stat.clicks[i]
          ids.map (i)=>stat.shows[i]
          ids.map (i)=>stat.spent[i]
        ]
      unless @plot
        @plot = new uPlot opts, data, @$refs["plot"]
      else @plot.setData data
      
    # --------------------------------------------------
    ...mapActions(["factDetails"])
  }

  mounted:->
    console.log @$route.params.platform, {name: @$route.params.id}
    @factDetails
      platform: @$route.params.platform
      qs: name: @$route.params.id
    
  computed:
    mapGetters ["fact_details"]
  watch:
    fact_details: (new_v, old_v)->
       @plot_statistics new_v
       
