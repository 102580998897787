import {auth} from '../../db.js'

# api_server = "https://vs290.ailove.ru:3000/"
api_server = location.origin
api = "#{api_server}/api"

# API GET
get = (method, qs={})->
  await (await fetch "#{api}/#{method}?#{new URLSearchParams(qs)}").json()

# API POST
post = (method, body)->
  res = await fetch "#{api}/#{method}",
    method: 'POST'
    headers: 'Content-Type': 'application/json;charset=utf-8'
    body: JSON.stringify body
  await res.json()

# API UPDATE
update = (method, body)->
  res = await fetch "#{api}/#{method}",
    method: 'PUT'
    headers: 'Content-Type': 'application/json;charset=utf-8'
    body: JSON.stringify body
  await res.json()

# API DELETE
del = (method, id)->
  res = confirm() && await fetch "#{api}/#{method}?id=#{id}", method: 'DELETE'
  await res.json()

# Конвертиурем даты в тексты
simplify_dates = (t)->
  for k, v of t when k[...4] is "Дата"
    t[k] = v[...10]
  t

# Конвертируем текстовые даты
unsimplify_dates = (t)->
  for k, v of t when k[...4] is "Дата"
    t[k] = new Date v
    t[k] = '' if isNaN(t[k])
  t
  
export default

  api_server: api_server
  api: api

  # ЭКШЕНЫ
  actions: 

    # Планы
    fetchPlans: (ctx, qs={})->
      plans = await get 'plans', qs
      ctx.commit "updatePlans", plans

    # Удаление плана
    deletePlan: (ctx, plan)->
      return unless confirm("Точно удалить #{plan.aiplan_id}?")
      res = await del 'plans', plan.aiplan_id
      # Удаляем план из списка если ок
      if res?.ok == 1
        ctx.commit('deletePlan', plan)
    updatePlan: (ctx, plan)->

    # Отправляем XLSM на сервер
    uploadXlsm: (ctx, {xlsm, name})->
      req = new XMLHttpRequest
      req.open "POST", "#{api}/xlsm", true
      fd = new FormData
      fd.append "file", new Blob([xlsm], {type: "application/vnd.ms-excel.sheet.macroEnabled.12"}), name
      fd.append "user", auth.currentUser.email
      req.onload = ->
        if @readyState is 4 and @status is 200
          ctx.commit "updateNewPlan", JSON.parse @response
      req.send fd

    # Инструменты
    fetchTools: (ctx, qs={})->
      tools = await get 'tools', qs
      tools = tools.map simplify_dates
      ctx.commit "updateTools", tools

    # Обновление инструментов
    updateTools: (ctx, tools)->
      tools = tools.map unsimplify_dates
      res = await update 'tools', tools
      # if res?.ok == 1
      #  ctx.commit('updateTool', plan)

    # Отчеты об ошибках
    fetchReports: (ctx, qs={})->
      reports = await get 'reports', qs
      ctx.commit "updateReports", reports
    deleteReports: (ctx, tool)->
    updateReports: (ctx, tool)->

    fetchUpdateStatus: (ctx)->
      ctx.commit 'updateStatus', await get 'facts/update_status'
    fetchNamingStatus: (ctx)->
      ctx.commit 'namingStatus', await get 'facts/naming_status'
    fetchUpdateFacts: (ctx)->
      get 'facts/update'
    fetchTestNaming: (ctx, {platform, naming})->
      get "facts/test_naming/#{platform}", {naming}
    
    searchFacts: (ctx, qs={})->
      facts = await get 'facts/search', qs
      ctx.commit "updateFoundFacts", facts

    factDetails: (ctx, {platform, qs})->
      console.log platform, qs
      ctx.commit "updateFactDetails", null
      fact = await get "fact/details/#{platform}", qs
      fact.statistics = []
      fact.date?.map (d,i)->
        s = {}
        s.date   = fact.date[i]
        s.spent  = fact.spent[i]
        s.shows  = fact.shows[i]
        s.clicks = fact.clicks[i]
        fact.statistics[i] = s
      ctx.commit "updateFactDetails", fact

  # МУТАЦИИ
  mutations:

    updatePlans: (state, plans)-> 
      state.plans = plans

    deletePlan: (state, plan)->
      i = state.plans.indexOf(plan)
      state.plans.splice(i, 1)

    updateTool:        (state, tool   )->
    updateTools:       (state, tools  )-> state.tools         = tools
    updateReports:     (state, reports)-> state.reports       = reports
    updateStatus:      (state, status )-> state.update_status = status
    namingStatus:      (state, status )-> state.naming_status = status
    updateFoundFacts:  (state, facts  )-> state.found_facts   = facts
    updateFactDetails: (state, fact   )-> state.fact_details  = fact
    updateNewPlan:     (state, plan   )-> state.new_plan      = plan
    
  # СТЭЙТЫ
  state:
    plans:   []
    tools:   []
    reports: []
    update_status: {}
    naming_status: {}
    found_facts:   []
    fact_details:  {}
    new_plan: undefined

  # ГЕТЕРЫ
  getters:
    allPlans:     (state)-> state.plans
    allTools:     (state)-> state.tools
    allReports:   (state)-> state.reports
    updateStatus: (state)-> state.update_status
    namingStatus: (state)-> state.naming_status
    found_facts:  (state)-> state.found_facts
    fact_details: (state)-> state.fact_details
    new_plan:     (state)-> state.new_plan
