default_user = "robot@i-com-agency.ru"

# таблица соответствия колонок в отчете и плане
plan_tool_cols = [
  # в базе           в отчете           в плане
  [ "creation_date", "Дата создания"  , undefined                               ]
  [ "update_date"  , "Дата изменения" , undefined                               ]
  [ "start_date"   , "Дата старта"    , "Дата старта"                           ]
  [ "stop_date"    , "Дата завершения", "Дата завершения"                       ]
  [ "fact_date"    , "Дата факта"     , undefined                               ]

  [ "created_by"   , "Создано"        , undefined                               ]
  [ "updated_by"   , "Изменено"       , undefined                               ]
  
  [ "aiplan_id"    , "Айплан ID"      , undefined                               ]
  [ "plan_id"      , "Позиция"        , undefined                               ]
  [ "client"       , "Клиент"         , "Клиент"                                ]
  [ "brand"        , "Бренд"          , "Бренд"                                 ]
  [ "tool"         , "Инструмент"     , "Инструмент"                            ]
  [ "campaign"     , "Кампания"       , "Кампания"                              ]
  [ "plan_views"   , "План показов"   , "Показы / прогноз"                      ]
  [ "fact_views"   , "Факт показов"   , undefined                               ]
  [ "plan_clicks"  , "План клики"     , "Клики / прогноз"                       ]
  [ "fact_clicks"  , "Факт клики"     , undefined                               ]
  [ "plan_spent"   , "План закупки"   , "Стоимость после скидки, РУБ (без НДС)" ]
  [ "fact_spent"   , "Факт закупки"   , undefined                               ]
  [ "plan_conv"    , "План заявок"    , "Конверсии / прогноз"                   ]
  [ "fact_conv"    , "Факт заявок"    , undefined                               ]
  [ "plan_cr"      , "План CR"        , "CR % /  прогноз"                       ]
  [ "fact_cr"      , "Факт CR"        , undefined                               ]
  [ "plan_vtr"     , "План VTR"       , "VTR, % / прогноз "                     ]
  [ "fact_vtr"     , "Факт VTR"       , undefined                               ]
  [ "fact_misc"    , "Факт прочее"    , undefined                               ]
  [ "fact_status"  , "Статус"         , undefined                               ]
 
  # Поля необходимые для метчинга в базах
  [ "cabinet_naming", "Нейминг в кабинете", "Нейминг в кабинете"]
]

# собираем нормализацию
col_name_normalize = (s)->
    s
    .toLowerCase()
    .trim()
    .replace /[^a-zа-я_ #]*/g, ''
    .replace /\s+/g, ' '

plan_cols = plan_tool_cols.filter (c)->c[2]?
plan_cols.map (c)->c[3]=col_name_normalize c[2]
tool_cols = plan_tool_cols.reduce ((o,c,i)->o[c[0]]=c[1];o), {} # name -> tool_name
plan_cols = plan_tool_cols.reduce ((o,c,i)->o[c[0]]=c[2];o), {} # name -> plan_name
col_ids   = plan_tool_cols.reduce ((o,c,i)->o[c[0]]=i   ;o), {} # name -> index

# Конверто md5 таблицы в массив
table_from_text = (table)->
  table
  .split  /\n+/             # Режем на строки
  .filter (r)->r.length     # Фильтруем пустые
  .map    (r)->
    r                       # Каждую строку
    .replace /\s+/g, ' '    #   очищаем от длинных пробелов
    .split   /\s*\|\s*/     #   режем по |
    .map     (c)->c.trim()  #   убираем лишние пробелы с коцов
    .filter  (c)->c.length  #   фильтруем пустые ячейки

pg_plan_cols = table_from_text """
  aiplan_id     | varchar(128) UNIQUE
  created_by    | varchar(128)
  creation_date | date
  updated_by    | varchar(128)
  update_date   | date
  clients       | varchar
  brands        | varchar
  campaigns     | varchar
  tools         | varchar
  """

# Нормализация -> lower_case + remove [^a-zа-я ] + trim
# в базе           тип           в xlsx после нормализации
pg_tool_cols = table_from_text """
  aiplan_id      | varchar(256) |
  tmp_id         | integer      |
  mp_id          | integer      | #
  naming         | varchar(256) | нейминг в кабинете
  created_by     | varchar(256) |
  updated_by     | varchar(256) |
  client         | varchar(256) | клиент
  brand          | varchar(256) | бренд
  tool           | varchar(256) | инструмент
  campaign       | varchar(256) | кампания
  creation_date  | date         |
  update_date    | date         |
  start_date     | date         | дата старта
  end_date       | date         | дата завершения
  planned_views  | integer      | показы прогноз
  planned_clicks | integer      | клики установки прогноз
  planned_spend  | float        | стоимость после скидки руб без ндс
  planned_cr     | float        | cr прогноз
  planned_vtr    | float        | vtr прогноз 
  views          | integer      |
  imps           | integer      |
  clicks         | integer      |
  spend          | float        |
  cr             | float        |
  vtr            | float        |
  """

# мэпинг назаваний колонок pg >> xlsx
xlsx_cols = {}
pg_tool_cols
.filter (r)-> r[2]
.map    (r)-> xlsx_cols[r[0]] = r[2]

# мэпинг назаваний колонок pg >> тип
xlsx_types = {}
pg_tool_cols
.map (r)-> xlsx_types[r[0]] = r[1].replace /[^a-z]/g, ''
  
if require.main is module
  console.log pg_tool_cols
else
  module.exports = {
    plan_tool_cols
    col_name_normalize
    tool_cols
    plan_cols
    col_ids
    pg_plan_cols
    pg_tool_cols
    xlsx_cols
    xlsx_types
    default_user
  }
