





































import {storage, db, sts, auth} from "../db.js"
import XLSX from 'xlsx'
import Spreadsheet from "x-data-spreadsheet"

plans = db.collection('plans')

export default

  name: "PlanEdit"
  
  props: ["plan"]

  data:->
    planChanged: false
    select: user: "-", ts: "-"
    history:[]
    cols:[ 
      { name: "aiplan_id"                               }
      { name: "client"                                  }
      { name: "brand"                                   }
      { name: "Источник"                                }
      { name: "start_date"                              }
      { name: "end_date"                                }
      # { name: "Единица закупки"                         }
      # { name: "Цена за единицу, РУБ итоговая (без НДС)" }
      # { name: "Кол-во единиц"                           }
      { name: "planned_views"                            }
      { name: "planned_clicks"                          }
      { name: "planned_spend"                            }
      # { name: "План KPI"                                }
      # { name: "CR % /  прогноз"                         }
      # { name: "VTR, % / прогноз "                       }
    ]
    headers: []
    spreadsheet: undefined
  
  methods:
    onUpdate:->
      @planChanged = true
    cancel:->
      @planChanged = false
      @$emit('cancel-edit')
    save:->
      await plans.doc(@plan.name).set({
        user: auth?.currentUser?.email
        data: @plan.data
        ts: sts()
      }, merge: true);
      # Сохраняем копию в историю
      await plans.doc(@plan.name).collection("history").doc("#{Date.now()}").set
        ts: sts()
        user: auth?.currentUser?.email
        data: @plan.data
      @cancel()
    selectText:(item)->"#{@ut2datetime(parseInt(item.id))} (#{item.user.split('@')[0]})"
    historyChange: (id)->
      doc = await plans.doc(@plan.name).collection("history").doc(id).get()
      @plan.data = doc.data().data
  watch:
    plan: 
      immediate: true
      handler: (plan)->
        @$bind('history', plans.doc(plan.name).collection("history").orderBy('ts', 'desc'))
    

