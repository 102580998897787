


















import {mapGetters, mapActions} from "vuex"
export default

  name: "ReportsList"  
  data: -> {}

  mounted: ->
    @fetchUpdateStatus()
    
  methods: {
  
    updateFact:->
      await @fetchUpdateFacts()
      @fetchUpdateStatus()
      @periodicalyUpdateStatus()
      
    # Периодически запрашиваем обновление статуса
    periodicalyUpdateStatus: ->
      setTimeout =>
        @fetchUpdateStatus()
        @periodicalyUpdateStatus() unless @updateStatus?.status is "ready"
      , 5000
    
    ...mapActions(['fetchUpdateStatus', 'fetchUpdateFacts'])
  }
  computed: {
    statusColor: ->
      switch @updateStatus?.status
        when "ready"    then "green"
        when "error"    then "red"
        when "updating" then "blue"
        else "grey"
    ...mapGetters ["updateStatus"]
  }
