


























import {mapGetters, mapActions} from "vuex"
import TestNaming from "./TestNaming"
import UpdateFacts from "./UpdateFacts"

export default

  name: "ReportsList"
  components: {UpdateFacts, TestNaming}
  data: ->
    headers: [
      {text: "Дата"        , value: "Дата"}
      {text: "Платформа"   , value: "Платформа"}
      {text: "Найден факт" , value: "Найден факт"}
      {text: "Инструментов", value: "Инструментов"}
    ]
    report: null
    dialog: false
    
  methods:  
    onClick:(report)->
      @report = report
      
  computed: mapGetters ["allReports"]

