api = location.origin + "/api"

get = (method, qs={})->await (await fetch "#{api}/#{method}", {qs}).json()
post = (method, body)->
  res = await fetch "#{api}/#{method}",
    method: 'POST'
    headers: 'Content-Type': 'application/json;charset=utf-8'
    body: JSON.stringify body
  await res.json()

module.exports = 
  get_meta: -> get 'meta'
  get_plans: (qs={})-> get 'plans', qs
  create_plan: (plan)-> post "plans", plan
   