var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('div',{staticClass:"text-h3"},[_vm._v("vk")]),_c('v-data-table',{staticClass:"table-cursor",attrs:{"items":_vm.found_facts.vk,"headers":_vm.headers},on:{"click:row":function($event){return _vm.onClick('vk', $event)}},scopedSlots:_vm._u([{key:"item.ts",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.ts2date(item.ts)))]}}])}),_c('div',{staticClass:"text-h3"},[_vm._v("yandex")]),_c('v-data-table',{staticClass:"table-cursor",attrs:{"items":_vm.found_facts.yandex,"headers":_vm.headers},on:{"click:row":function($event){return _vm.onClick('yandex', $event)}},scopedSlots:_vm._u([{key:"item.ts",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.ts2date(item.ts)))]}}])}),_c('div',{staticClass:"text-h3"},[_vm._v("facebook")]),_c('v-data-table',{staticClass:"table-cursor",attrs:{"items":_vm.found_facts.facebook,"headers":_vm.headers},on:{"click:row":function($event){return _vm.onClick('facebook', $event)}},scopedSlots:_vm._u([{key:"item.ts",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.ts2date(item.ts)))]}}])}),_c('div',{staticClass:"text-h3"},[_vm._v("mytarget")]),_c('v-data-table',{staticClass:"table-cursor",attrs:{"items":_vm.found_facts.mytarget,"headers":_vm.headers},on:{"click:row":function($event){return _vm.onClick('mytarget', $event)}},scopedSlots:_vm._u([{key:"item.ts",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.ts2date(item.ts)))]}}])})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-card',[_c('v-card-title',[_vm._v("Фильтр по названию")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Нейминг","clearable":""},on:{"change":_vm.updateNameFilter,"click:clear":_vm.clearNameFilter},model:{value:(_vm.nameFilter),callback:function ($$v) {_vm.nameFilter=$$v},expression:"nameFilter"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }