









































import {mapGetters, mapActions} from "vuex"

export default

  name: "ReportsList"  
  data: ->
    dialog: false
    platform:"vk"
    naming: null

  mounted: ->
    @fetchNamingStatus()
    
  methods: {
  
    checkNaming: ->
      # Отправлем запрос на проверку
      await @fetchTestNaming {platform: @platform, naming: @naming}
      # Сразу обновляем статус
      @fetchNamingStatus()
      # Периодически перепроверяем статус
      @periodicalyUpdateStatus()
      
    # Периодически запрашиваем обновление статуса
    periodicalyUpdateStatus: ->
      setTimeout =>
        @fetchNamingStatus()
        @periodicalyUpdateStatus() unless @namingStatus?.status is "ready"
      , 5000

    onClick:(report)->
      @report = report
    
    ...mapActions(['fetchTestNaming', 'fetchNamingStatus'])
  }
  
  computed: {
    statusColor: ->
      switch @namingStatus?.status
        when "ready"    then "green"
        when "error"    then "red"
        when "updating" then "blue"
        else "grey"
    ...mapGetters ["namingStatus"]
  }
