













import {mapGetters, mapActions} from "vuex"
import PlanEdit from "./PlanEdit"

export default

  name: "PlansList"  

  components: { PlanEdit }

  data: ->
    plans: [],
    planToEdit: null,
    headers: [
      { text: "Aiplan id"     , value: "aiplan_id" }
      { text: "Клиент"        , value: "clients"   }
      { text: "Бренд"         , value: "brands"    }
      { text: "Кампания"      , value: "campaigns" }
      { text: "Инструменты"   , value: "tools"     }
      # { text: "Создан"        , value: "created_by"}
      # { text: "Дата"          , value: "creation_date", type: "date" }
      { text: null, value: 'actions', sortable: false },
    ]

  computed:
    mapGetters(["allPlans"])

  methods: {
    downloadPlan: (item)->window.open(item.url, '_blank')
    onRowClick: (item)->
      @$emit "planSelected", item
    editPlan: (plan)-> @planToEdit = plan
    ...mapActions(["fetchPlans", "deletePlan"])
  }
  
  mounted: ->
    @fetchPlans()

