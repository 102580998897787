Zip = require "jszip"
fxp = require "fast-xml-parser"

x2j = fxp.parse
opt =
  ignoreAttributes: false
  attributeNamePrefix: ""
  attrNodeName: "attr"
  textNodeName: "text"
j2x = new fxp.j2xParser opt

# technical media plan id(#) column
tmp_id = "E"

formula_cells = "CL CM CN CO".split " "
start_row = 10

get_cell = (cells, pos)=>
  cells.filter((c)->c.attr?.r == pos)[0]

# Обновляем формулу 
update_formula = (cell)=>
  if cell and cell.f
    delete cell.v

# Распаковывет xlsm
decompress_xlsm = (xlsm)->
  await Zip.loadAsync xlsm

# Запаковывет xlsm
compress_xlsm = (zip)->
  zip.generateAsync {
    type: 'uint8array'
    mimeType: 'application/vnd.ms-excel.sheet.macroEnabled.12'
    compression: "DEFLATE"
    compressionOptions: level: 9
  }

# Получает на вход xlsm файл
# - Расспаковывает его как zip
# - Модифицирует sheet2.xml содержащий медиаплан
#   Добавляет в колонку A, iID, сгенерированные ID
#   Собирает словарь # -> iID
# - Модифицирует sheet1.xml содержащий технический медиаплан
#   Добавляет в колонку CK, iID из sheet2.xml согласно номеру в колонке Е, #
#   Сбрасывает содержимое в колонках с зависимыми формулами CL, CM, CN, CO
# - Запаковывет файлы назад в xlsm

generate_iIDs = (xlsm)->
  
  zip = await decompress_xlsm xlsm

  # Обрабатываем медиаплан
  fname = "xl/worksheets/sheet2.xml"
  xml = await zip.file(fname).async "text"
  json = x2j xml, opt
  rows = json?.worksheet?.sheetData?.row
  iIDs = {}
  for r in rows
    row = r.attr.r
    B = get_cell r.c, "B#{row}"
    if B and B.attr.t isnt 's' and typeof B.v is 'number'
      A = get_cell r.c, "A#{row}"
      unless A
        A = attr: r: "A#{row}"
        r.c.push A
      A.attr.t = 'str'
      iID = "#{Math.random().toString(36)[-5..]}"
      iIDs[B.v] = pos: "A#{row}", id: iID
      A.v = text: iID
  # generate xml
  xml = j2x.parse json
  # put back
  zip.file(fname, xml)
 
  # обрабатываем технический медиаплан
  fname = "xl/worksheets/sheet1.xml"
  xml = await zip.file(fname).async "text"
  json = x2j xml, opt
  rows = json?.worksheet?.sheetData?.row
  for r in rows
    row = r.attr.r
    id_c = get_cell r.c, "#{tmp_id}#{row}"
    if E and E.attr.t isnt 's' and typeof E.v is 'number'
      CK = get_cell r.c, "CK#{row}"
      unless CK
        CK = attr: r: "CK#{row}"
        r.c.push CK
      # CK.attr.t = 'str'
      id = "#{id_c.v}"
      CK.f = text: "Медиаплан!#{iIDs[id].pos}"
      CK.v = text: iIDs[id].id
      CK.attr.t = "str"
      delete CK.v

    # Update formulas in cells
    for col in formula_cells
      update_formula get_cell r.c, "#{col}#{row}"
      
  # generate xml
  xml = j2x.parse json
  # put back
  zip.file fname, xml 

  await compress_xlsm zip
  
module.exports = {
  generate_iIDs
}
