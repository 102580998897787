


















import {auth} from './db.js'
import Drawer from './components/Drawer'

export default
  name: 'App'
  components: { Drawer }
  data:-> drawer: false
  
  computed:
    user:->auth?.currentUser

  methods:
    home:->
      this.$router.push("/")
  created:->
  
