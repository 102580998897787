






import Improvado    from "./Improvado"

export default

  name: "Fact"  
  components: {Improvado}
  data: -> {}
  methods: {}
  mounted: ->
 
