










import {get_meta} from "../ds"
export default

  name: "PlanSelector"

  data:->
    clients:   []
    brands:    []
    tools:     []
    campaigns: []
    
    selected_clients:   []
    selected_brands:    []
    selected_tools:     []
    selected_campaigns: []
    
  methods:
    onselect:->
      console.log @selected_clients, @selected_brands, @selected_tools, @selected_campaigns

  mounted: ->
    json = await get_meta()
    @clients   = json.clients
    @brands    = json.brands
    @tools     = json.tools
    @campaigns = json.campaigns
    
