














import PlanUpload   from "./PlanUpload"
import PlanSelector from "./PlanSelector"
import PlansList    from "./PlansList"
import ReportsList  from "./ReportsList"
import ToolsListH   from "./ToolsListH"
import Improvado    from "./Improvado"

export default

  name: "PlanFact"  
  components: {
    PlanUpload
    PlanSelector
    PlansList
    ReportsList
    ToolsListH 
    Improvado}
  data: ->
    tab: null
    selectedPlan: null
  methods:
    onPlanSelect: (plan)->
      @tab = 1
      setTimeout (=>@selectedPlan = plan), 500
  mounted: ->
    @$store.dispatch('fetchPlans')
    @$store.dispatch('fetchTools')
    @$store.dispatch('fetchReports')
  
