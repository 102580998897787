import config from './config'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import { firestorePlugin } from 'vuefire'
import { auth } from './db'
import VueExcelEditor from 'vue-excel-editor'
// import VCalendar from 'v-calendar';

Vue.use(VueExcelEditor)
Vue.use(firestorePlugin)
// Vue.use(VCalendar, { componentPrefix: 'vc' });

Vue.config.productionTip = false;

for(let k in config) Vue.prototype[k] = config[k];
 
Vue.prototype.ut2date = (ut)=> {
  if(!ut) return "--/--/--";
  let d = new Date(ut);
  return `${d.getFullYear()}/${d.getMonth()+1}/${d.getDate()}`;
}

Vue.prototype.ut2datetime = (ut)=> {
  if(!ut) return "--/--/--";
  let d = new Date(ut);
  return `${d.getFullYear()}/${d.getMonth()+1}/${d.getDate()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
}

Vue.prototype.ts2date = (ts)=> {
  if(!ts) return "--/--/--";
  return Vue.prototype.ut2date(ts.seconds*1000)
}

Vue.prototype.$eventbus = new Vue();

let app;
auth.onAuthStateChanged( ()=> {
  console.log(auth);
  if(!app) app = new Vue({
    router,
    vuetify,
    store,
    render: h => h(App)
  }).$mount('#app')
})
